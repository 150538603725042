<template>
  <div>
    <div class="d-flex align-items-center justify-content-center" v-if="isLoading"><ChLoading size="lg" /></div>
    <div v-else>
      <div
        class="position-relative d-flex slide event-detail-preview"
        :class="showEventImage"
        :style="isAuthenticated ? 'height: 250px' : ''"
      >
        <div
          v-if="get(eventData, 'bg_image_url')"
          :style="`background-image: url(${get(eventData, 'bg_image_url')})`"
          class="slide-bg position-absolute"
        />
        <div class="mx-auto events-preview preview d-flex align-items-center">
          <div class="d-flex flex-column">
            <!-- <div class="grp-sub-txt">{{ get(eventData, 'group.name') }}</div> -->
            <div class="event-title">{{ truncateEasy(eventData.title, isMobSmallScreen ? 45 : 100) }}</div>
            <div class="mt-1 d-flex flex-column">
              <small class="quest-prize" v-if="!isAuthenticated">Complete Quests to get prizes</small>
              <ion-button class="join-btn" @click="joinChallenge">Join the Challenge! </ion-button>
            </div>
          </div>
        </div>
      </div>

      <ion-page id="characters-page" class="page scrollable bg-transparent" style="max-width: none !important">
        <div class="events-preview">
          <p class="mb-2">
            <router-link :to="{ name: 'events' }">
              <span class="small clickable-item-hov"> See all Events </span>
            </router-link>
            <span class="small clickable-item-hov"> > </span>
            <router-link to="#">
              <span class="small clickable-item-hov">
                <strong>{{ eventData.title }}</strong></span
              >
            </router-link>
          </p>
          <div class="w-100">
            <div class="title">Description:</div>
            <div v-show="eventData.description" class="text-black description" v-html="eventData.description" />
          </div>
          <p v-if="dateHasPassed(eventData.ends_at)">Event has ended</p>
          <!-- <div class="d-flex align-items-center">
        <strong class="point-text">My Points</strong>
        <img loading="lazy" v-user-image class="mx-1 char-img" :src="user.profile_picture_url" />

        <router-link
          v-if="get(user, 'username')"
          :to="{ name: 'profile', params: { username: get(user, 'username') } }"
        >
          <strong>{{ get(user, 'username') }}</strong></router-link
        >
      </div>

      <div class="points my-0">
        <span class="text-black"> You have earned </span>
        <strong>{{ eventData.user_points }}</strong>
        <span class="text-black ml-1"> {{ pointNamePlural }} from this event. </span>
      </div> -->

          <div class="mb-2" v-if="!isEmpty(get(eventData, 'legal_terms'))">
            <span class="small clickable-item-hov" @click="openTermsModal">
              <strong>See Terms & Conditions</strong></span
            >
          </div>
          <div class="mb-3" id="event-tabs-preview">
            <event-tabs :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />
            <div class="m-3">
              <submission-horizontal-card
                v-if="currentTab === 'ongoing'"
                :submission-tasks="ongoingTasks"
                :submitted-tasks="userSubmittedTasks"
                :current-tab="currentTab"
                @updated="recalcTaskTimes"
              />

              <submission-horizontal-card
                v-if="currentTab === 'upcoming'"
                :submission-tasks="upcomingTasks"
                :current-tab="currentTab"
                @updated="recalcTaskTimes"
              />

              <submission-horizontal-card
                v-if="currentTab === 'submission'"
                :submission-tasks="tasksSubmittedTo"
                :current-tab="currentTab"
                @updated="recalcTaskTimes"
                hideHasSubmitted
              />
            </div>
          </div>
          <SignupModal
            :is-open="isOpenSignUpModal"
            @close="isOpenSignUpModal = false"
            @signUp="openSiteEventTermsModal"
          />
          <EventRulesModal
            :is-open="isOpenEventRulesModal"
            @close="closeAndOpenQuestModal"
            @cancel="closeTermsModal"
            :events="eventData"
            :isEvent="true"
            :isTermsAndConditions="isTermsAndConditions"
          />
          <QuestParticipationModal
            :is-open="isOpenParticipationModal"
            :ongoingTasks="ongoingTasks"
            @updated="recalcTaskTimes"
          />
        </div>
      </ion-page>
    </div>
  </div>
</template>

<script lang="ts" setup>
import EventTabs from './components/event-tabs.vue';
import SubmissionHorizontalCard from './components/SubmissionHorizontalCardPreview.vue';
import { formatDescDateTime, formatTimeAgo, dateHasPassed, formatFromNowInMilliseconds } from '@/shared/utils/dateTime';
import { SiteEvent, SiteEventTask, SiteEventTaskSubmission, Tab } from '@/shared/types/static-types';
import { getEventDetails, getEventTasks, getEventTasksWithSubmissions } from '@/shared/actions/events';
import { eventDetailsTabs } from '@/shared/statics/tabs';
import { authStore } from '@/shared/pinia-store/auth';
import SignupModal from '~/shared/modals/SignupModal.vue';
import { useChHead } from '~/composables/useChHead';
import QuestParticipationModal from '~/shared/modals/QuestParticipationModal.vue';
import EventRulesModal from '~/shared/modals/EventRulesModal.vue';
import { onboardingStore } from '~/shared/pinia-store/onboarding';
import { truncateEasy } from '@/shared/utils/string';
const route = useRoute();
const eventData = ref<SiteEvent>({ id: '', title: '', slug: '' });
const isOpenParticipationModal = ref(false);
const tasks = ref([]) as Ref<SiteEventTask[]>;
const submissions = ref([]) as Ref<SiteEventTaskSubmission[]>;
const ongoingTasks = ref([]) as Ref<SiteEventTask[]>;
const userSubmittedTasks = ref([]) as Ref<SiteEventTask[]>;
const upcomingTasks = ref([]) as Ref<SiteEventTask[]>;
const tabs = ref(eventDetailsTabs);
const currentTab = ref('ongoing');
const tabIndex = ref(0);
const subTabIndex = ref(0);
const isLoading = ref(true);
const tasksSubmittedTo = ref([]) as any;
const upcomingSubmissionTasks = ref([]);
const isOpenSignUpModal = ref(false);
const picture = ref('');
const title = ref('');
const description = ref('');
const pageTitle = ref('');
const url = ref(`https://characterhub.com${route.path}`);
const previewContainer: Ref = ref(null);
const router = useRouter();
const isOpenEventRulesModal = ref(false);
const isTermsAndConditions = ref(false);
const { isAuthenticated, user, updateEventSignUp } = authStore();
const { initOnboarding } = onboardingStore();
const { isMobSmallScreen } = useWindowSize();
const currentEventSlug = computed(() => {
  return router.currentRoute.value.params.slug;
});

const showEventImage = computed(() => {
  return isAuthenticated.value ? '' : 'position-fixed';
});
const openTermsModal = () => {
  isOpenEventRulesModal.value = true;
  isTermsAndConditions.value = true;
};

const openSiteEventTermsModal = () => {
  isOpenSignUpModal.value = false;
  !isEmpty(get(eventData.value, 'legal_terms')) ? (isOpenEventRulesModal.value = true) : handleOngoingTasks();
};
const handleOngoingTasks = () => {
  const tasks = ongoingTasks.value as any;

  const taskCount = tasks.length;

  if (taskCount > 1) {
    isOpenParticipationModal.value = true;
  } else if (taskCount === 1) {
    const firstTask = tasks[0];
    getTaskPath(firstTask);
  } else {
    updateEventSignUp(false);
    router.push({ name: 'profile-self' });
    initOnboarding();
    return;
  }
};

const closeAndOpenQuestModal = () => {
  isOpenEventRulesModal.value = false;
  handleOngoingTasks();
};

const closeTermsModal = () => {
  isOpenEventRulesModal.value = false;
  setTimeout(() => {
    isTermsAndConditions.value = false;
  }, 150);
};
const hasUserSubmitted = (task: SiteEventTask) => {
  return task.user_submission_count && task.user_submission_count > 0;
};

const joinChallenge = () => {
  const document = useDocument();
  isAuthenticated.value
    ? nextTick(() => {
        document.value.getElementById('event-tabs-preview')?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
      })
    : (isOpenSignUpModal.value = true);
};

const getTaskPath = (task: SiteEventTask) => {
  if (task.task_type === 'poll') return router.replace(`/events/poll/${task.id}`);
  return router.replace(`/events/task/${task.id}`);
};

const pointNamePlural = computed(() => {
  let pointName = 'point';
  if (eventData.value.point_name) pointName = eventData.value.point_name;
  if (!eventData.value.user_points || eventData.value.user_points !== 1) pointName += 's';
  return pointName;
});

const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
  router.replace({ name: 'event-details-preview', params: { slug: route.params.slug }, query: { tab: value } });
};

const fetchEventDetails = async () => {
  eventData.value = await getEventDetails(currentEventSlug.value as string);
  const [data, tasksSubmittedTo] = await Promise.all([
    getEventTasks(eventData.value.id as string),
    getEventTasksWithSubmissions(eventData.value.id as string),
  ]);
  tasksSubmittedTo.value = tasksSubmittedTo.results;
  tasks.value = data.results;
  ongoingTasks.value = tasks.value.filter((task: SiteEventTask) => {
    return (
      task.user_submission_count === 0 &&
      (!task.ends_at || !dateHasPassed(task.ends_at)) &&
      (!task.starts_at || dateHasPassed(task.starts_at))
    );
  });

  userSubmittedTasks.value = tasks.value.filter(
    (task: SiteEventTask) => task.user_submission_count && task.user_submission_count > 0
  );

  upcomingTasks.value = tasks.value.filter((task: SiteEventTask) => task.starts_at && !dateHasPassed(task.starts_at));
};

const recalcTaskTimes = () => {
  ongoingTasks.value = tasks.value.filter(
    (task: SiteEventTask) =>
      (!task.ends_at || !dateHasPassed(task.ends_at)) && (!task.starts_at || dateHasPassed(task.starts_at))
  );
  upcomingTasks.value = tasks.value.filter((task: SiteEventTask) => task.starts_at && !dateHasPassed(task.starts_at));
};
await useChAsyncData(async () => {
  await fetchEventDetails();
  pageTitle.value = `${eventData.value.title} - CharacterHub`;
  description.value = `Read more about ${eventData.value.title} on CharacterHub!`;
  title.value = `${eventData.value.title}`;
  picture.value = eventData.value.image_url || eventData?.value?.bg_image_url || '';

  useChHead(pageTitle, title, description, url, picture);

  setTimeout(() => {
    isLoading.value = false;
  }, 50);
});
onMounted(async () => {
  const document = useDocument();
  document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
});
</script>
<style lang="sass" scoped>
.quest-prize
  color: white
  margin-left: 0.25rem
  @media(max-width:500px)
    display: none
.position-fixed
  position: sticky !important
  top: 0
  left: 0
  width: 100%
  z-index: 2
  height: 500px
  transition: 60ms ease all
  @media(max-width: 570px)
    height: 200px
.grp-sub-txt
  font-size: 18px
  font-weight: bold
  color: white
  @media(max-width: 570px)
    font-size: 16px
.event-title
  font: bolder 38px "Roboto Slab", sans-serif
  -webkit-text-fill-color: white
  margin-left: 0.25rem
  @media(max-width:570px) and (min-width:410px)
    font: bolder 30px "Roboto Slab", sans-serif
    line-height: 25px
  @media(max-width:410px)
    font: bolder 25px "Roboto Slab", sans-serif
    line-height: 25px
  @media(max-width:300px)
    font: bolder 20px "Roboto Slab", sans-serif

.char-img
  width: 15px !important
  height: 15px !important
  border-radius: 13px !important
.point-text
    color: #AE38E5
.dark .page
    background: #010123 !important
.page
    background:#F9F9FF !important
.join-btn
  color: #7050B7
  border: 3px solid #7050B7
  font-size: 20px
  font-weight: bold
  height: 45px
  width: 100%
  --background: white
  border-radius: 10px

.preview
  @media(max-width:800px)
    padding: 1rem
.events-preview
  z-index: 10
  @media(min-width:850px)
    max-width: 800px
    margin-left: auto !important
    margin-right: auto !important
  @media(max-width:1600px) and (min-width:1400px)
    max-width: 600px !important
  @media(max-width:1400px) and (min-width:1185px)
    max-width: 500px !important

.event-preview-scroll
    height: calc(100vh - 340px)
    overflow: auto !important
.background-text
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  position: absolute
  z-index: 1
  font: bolder 210px Roboto, sans-serif
  -webkit-text-fill-color: rgba(255, 255, 255, 0.1)
  text-align: center
  text-transform: uppercase

.clickable-item-hov
  color:  #AE38E5 !important

.points
  border: 1px solid #AE38E5
  border-radius: 12px
  padding: 10px
  color: #AE38E5

.event-slide-img
  @media(min-width:560px)
    width: 350px

.slide-img
  width: 100%
  object-fit: contain
  height: 100%

.slide
  width: 100%
  object-fit: cover
  background: linear-gradient(90deg, rgba(126, 224, 255, 0.4) 19.53%, rgba(95, 169, 238, 0) 35%, rgba(126, 224, 255, 0.4) 100%), #A83CFF
  @media(max-width: 800px)
    justify-content: center
    align-items: center
.slide-bg
  background-size: cover
  opacity: 0.3
  height: 100%
  width:100%
  background-position: center

.slide-img
  object-fit: contain
  z-index: 99

.clickable-item:hover
  opacity: 0.7
.title
  font-weight: bold
  font-size: 32px
  color: #7050B7
.submission-form
  margin-bottom: 1rem
.no-select
  user-select: none !important
.description
  flex: 1
  white-space: pre-wrap
  height: auto
  font-size: 14px
.expired
  color: gray !important
.event-name
  font-size: 1.15rem
a:hover
  opacity: 0.7
.completed
  position: relative
  top: 2px
  font-size: 10px
  margin-left: 5px
.evt-img
  img
    width: 200px
    object-fit: cover
    flex-shrink: 0
    width: 200px
.event-header
  display: flex
  flex-flow: row wrap
@media only screen and (max-width: 570px)
  .event-header
    flex-flow: column wrap
  .evt-img
    align-self: center
    margin-bottom: 0.2rem
</style>
