<template>
  <ion-segment
    scrollable
    mode="md"
    class="tabs compact no-shadow bg-transparent customize-tabs"
    style="margin-left: -12px"
    :value="activeIndex"
    @ion-change="segmentChanged($event)"
  >
    <ion-segment-button
      v-for="(tab, index) of tabs"
      :key="index"
      class="shrink bg-transparent"
      style="color: #5d5c5c; max-width: 100%"
      :class="{ hide: !isAuthenticated && route.name === 'event-details-preview' && tab.value === 'submission' }"
      :value="index"
    >
      <ion-label style="color: #a7aabe; font-size: 18px" class="m-0">{{ tab.name }}</ion-label>
    </ion-segment-button>
  </ion-segment>
</template>

<script lang="ts" setup>
import { Tab } from '@/shared/types/static-types';
import { authStore } from '~/shared/pinia-store/auth';
const { isAuthenticated } = authStore();
const route = useRoute();
const props = defineProps({
  tabs: {
    type: Array,
    default: [] as Tab[],
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
});

const tabs = toRef(props, 'tabs');
const activeIndex = toRef(props, 'activeIndex');
const emits = defineEmits(['update:activeIndex', 'tab-changed']);
const segmentChanged = ($event: CustomEvent) => {
  const tabIndex = $event.detail.value as number;
  const tab = tabs.value[tabIndex];

  emits('update:activeIndex', tabIndex);
  emits('tab-changed', tab);
};
</script>

<style scoped lang="sass">
.hide
  display: none !important
  visibility: hidden !important

ion-segment
  border-bottom: 1px solid #a7aabe

.dark  ion-segment
  border-bottom: 1px solid #a7aabe
// ion-segment-button
//   --indicator-color: #e6e6e600
.shrink
  flex: none !important

.dark  .segment-button-checked
  *
    color: white !important
.segment-button-checked
  *
    color: #7050B7 !important
</style>
