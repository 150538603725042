<template>
  <ion-modal class="modal-big" :is-open="isOpen" mode="md" :backdrop-dismiss="false" :show-backdrop="true">
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3 py-2">
          <div />

          <div class="title">Select a Challenge</div>
          <div>
            <!-- <ion-buttons slot="end">
              <ion-button @click="dismissModal()">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons> -->
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content p-3">
      <div class="sub-title">You can come back later to submit to more!</div>
      <div>
        <submission-horizontal-card
          :submission-tasks="ongoingTasks"
          :is-quest-modal="true"
          @updated="emits('updated')"
          @selected="questSelection"
        />
      </div>
    </div>

    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button @click="action" :disabled="isEmpty(selectedQuest)">Choose</ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import submissionHorizontalCard from '~/shared/pages/events/components/SubmissionHorizontalCardPreview.vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  ongoingTasks: {
    type: Array,
    default: () => [],
  },
});
const emits = defineEmits(['close', 'updated']);
const isOpen = toRef(props, 'isOpen');
const selectedQuest = ref('');
const router = useRouter();
const questSelection = (quest: any) => {
  selectedQuest.value = quest;
};
const dismissModal = () => {
  emits('close');
};
const action = () => {
  router.replace({ name: 'event-task', params: { id: selectedQuest.value } });
};
</script>
<style lang="sass" scoped>
.dark .toolbar
  --background: #17074c !important
.inner-content
  background: var(--ion-background-color, #f2f2f2)
.modal-inner-content
  background: var(--ion-background-color, #f2f2f2)
  max-height: 75dvh
  max-height: 75vh
  overflow-x: hidden
  overflow-y: auto
  padding: 1rem 1rem 0rem 1rem

.modal-big
  --width: 500px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
  font-weight: bold
.sub-title
  color: #214163
  font-size: 18px
</style>
